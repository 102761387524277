import React, { useEffect } from 'react';

import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import { useDispatch } from 'react-redux';
import { getOr } from 'lodash/fp';
import { merchant, auth } from '../state';
import { PreLoader } from '../components/custom';

const Auth = () => {
  const { search } = useLocation();
  const codeParam = search
    .replace('?', '')
    .split('&')
    .find((param) => param.startsWith('code='));
  const dispatch = useDispatch();
  const code = codeParam ? codeParam.split('=')[1] : null;
  const firstTimeParam = search
    .replace('?', '')
    .split('&')
    .find((param) => param.startsWith('firstTime='));
  const firstTime = firstTimeParam ? firstTimeParam.split('=')[1] : false;
  const getMerchantDetails = (payload) =>
    dispatch(merchant.actions.getMerchantDetails(payload));
  const loginByCode = async () => {
    const response = await dispatch(auth.actions.loginByCode({ code }));
    if (response.payload) {
      const res = await getMerchantDetails(response.payload);
      const isConnected = getOr(false, ['payload', 'merchantConnected'], res);
      if (!isConnected) {
        navigate('/dashboard/connect');
        return null;
      }
      // navigate('/dashboard');
    }
    navigate(`/dashboard/business-profile?firstTime=${firstTime}`);
    return null;
  };

  useEffect(() => {
    if (code) {
      loginByCode();
    } else {
      navigate('/');
    }
  }, [code]);
  return <PreLoader />;
};

export default Auth;
